import React from "react"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import {
  PostTemplate,
  Spinner,
  Carousel,
  CardListVertical,
  GradientBanner,
  Seo,
} from "../../components"
import "./insights-styles.scss"

const InsightsDetailView = ({ data, loading }) => {
  const { t } = useTranslation()
  if (loading) {
    return <Spinner loading={loading} />
  }
  return (
    <React.Fragment>
      <Seo title={_.get(data, "title")} description={_.get(data, "excerpt")} />
      <div className="insightsDetail__container">
        <div className="insightsDetail__content">
          <PostTemplate data={data} t={t} />
        </div>
      </div>
      <div className="insightsDetail__carousel">
        <div>
          {data.related && (
            <div className="">
              <div className="carousel__tagline">
                <h2>{t("insightsDetail.related")}</h2>
              </div>
              <div>
                <Carousel
                  data={data.related}
                  sToShow={3}
                  sToScroll={3}
                  arrows={true}
                >
                  {data.related.map((insight, i) => (
                    <div key={i} className="cardTeam__box">
                      <CardListVertical data={insight} />
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          )}
        </div>
      </div>
      <GradientBanner />
    </React.Fragment>
  )
}

export default InsightsDetailView
